import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/layout'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
} from 'react-share'
import { Facebook, Twitter, Mail, Linkedin } from 'react-feather'
import { RelatedProjects } from '../components/RelatedProjects'
import Breadcrumbs from '../components/breadcrumbs'

class BlogTemplate extends Component {
  render() {
    const post = this.props.data.contentfulBlogPost
    const siteurl = this.props.data.site.siteMetadata.siteUrl
    const relatedPosts = this.props.data.related

    const BlogPostLayout = styled.div`
      background: #fff;
      article {
        max-width: 900px;
        margin: 0 auto;
        padding: 1em;
      }
    `
    const Header = styled.div`
      text-align: left;
      padding: 1em 0;
      margin: 0 auto;
      padding-bottom: 0em;
      margin-bottom: 1em;
      border-bottom: 1px solid #eaeaea;
      h1 {
        font-size: calc(1.15vw + 1em);
        margin-bottom: 0.25em;
        line-height: 1.3;
        font-weight: 600;
      }
      border-bottom: 1px solid #f1f1f1;
      p {
        color: #666;
      }
    `

    const BlogPostImage = styled.div`
      img {
        max-width: 900px;
        margn: 0 auto;
        text-align: center;
        border-radius: 0;
        margin-bottom: 1em;
        box-shadow: none !important;
      }
    `
    const ShareThisPost = styled.div`
      padding-bottom: 1em;
      p {
        font-weight: bold;
        margin: 0;
      }
      display: flex;
      align-items: center;
      .SocialMediaShareButton {
        display: flex;
        padding: 0 9px;
      }
      svg {
        stroke: none;
        fill: #888;
        cursor: pointer;
      }
      .stroke-white {
        stroke: white;
      }
    `

    return (
      <Layout pageProps={this.props}>
        <div className="bg-white border-b border-gray-100">
          <nav
            className="flex container mx-auto px-4 py-3"
            aria-label="Breadcrumb"
          >
            <Breadcrumbs
              links={[
                { to: '/', label: 'Home' },
                { to: '/blog/', label: 'Blog' },
                {
                  to: this.props.location.pathname,
                  label: post.title,
                },
              ]}
            />
          </nav>
        </div>
        <SEO
          title={post.metaTitle ? post.metaTitle : post.title}
          description={post.metaDescription ? post.metaDescription : post.title}
          img={
            post &&
            post.heroImage &&
            post.heroImage.metaTagImage &&
            post.heroImage.metaTagImage.images &&
            post.heroImage.metaTagImage.images.fallback &&
            post.heroImage.metaTagImage.images.fallback.src
          }
        />

        <BlogPostLayout>
          <article itemScope itemtype="https://schema.org/Article">
            <Header>
              <h1 itemprop="name headline">{post.title}</h1>
              <p>
                <time itemprop="datePublished">{post.publishDate}</time> — by{' '}
                <span itemprop="author">{post.author.name}</span> in{' '}
                <Link
                  to={'/blog/category/' + post.category.slug}
                  title={'View all posts in ' + post.category.name}
                >
                  {post.category.name}
                </Link>
              </p>
            </Header>
            <ShareThisPost>
              <p>Share this article:</p>
              <FacebookShareButton
                url={siteurl + '/blog/' + post.slug}
                quote={post.title}
                hashtag={'#solidconstructiondesign'}
              >
                <Facebook size={20} />
              </FacebookShareButton>

              <TwitterShareButton
                url={siteurl + '/blog/' + post.slug}
                title={post.title}
                hashtags={post.tags}
              >
                <Twitter size={20} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={siteurl + '/blog/' + post.slug}
                title={post.title}
                quote={post.title}
                hashtags={post.tags}
              >
                <Linkedin size={20} />
              </LinkedinShareButton>
              <EmailShareButton
                subject={post.title}
                url={siteurl + '/blog/' + post.slug}
                openWindow={true}
                body={'Check out this article: '}
              >
                <Mail size={20} className="stroke-white" />
              </EmailShareButton>
            </ShareThisPost>
            <BlogPostImage>
              <GatsbyImage
                image={getImage(post.heroImage)}
                style={{
                  height: '400px',
                  objectFit: 'cover',
                  boxShadow: '1px 2px 8px 1px hsla(0,0%,0%,0)',
                  marginBottom: '1em',
                }}
                alt={post.title}
                className="blogPostImage"
                itemprop="image"
              />
            </BlogPostImage>
            <div
              itemprop="articleBody"
              className="blogarticlestyle"
              dangerouslySetInnerHTML={{ __html: post.body.body }}
            />
            <p>
              Tags:{' '}
              {post.tags.map((item, i) => (
                <span itemprop="keywords" key={i}>
                  {' ' + item},
                </span>
              ))}
            </p>
          </article>
        </BlogPostLayout>
        {relatedPosts.edges &&
          relatedPosts.edges &&
          relatedPosts.edges.length > 0 && (
            <div className="container mx-auto px-3 py-8">
              <h2>More in {post.category.name}</h2>
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-3">
                {relatedPosts.edges.map((item, i) => (
                  <Link
                    key={i}
                    to={`/blog/${item.node.slug}`}
                    className="bg-white rounded-lg shadow hover:shadow-lg"
                  >
                    <GatsbyImage
                      image={getImage(item.node.heroImage)}
                      style={{ height: '150px' }}
                      alt={item.node.title}
                    />
                    <div className="p-3 py-4">
                      <span className="text-gray-700 text-base">
                        {item.node.publishDate}
                      </span>
                      <span className=" font-semibold leading-tight text-gray-900 block">
                        {item.node.title}{' '}
                      </span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        <RelatedProjects data={this.props.data.relatedProjects} />
      </Layout>
    )
  }
}

export default BlogTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $categoryId: String
    $testimonialCategorySlugExp: String
  ) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      metaTitle
      metaDescription
      metaRobotsContent
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        gatsbyImageData(width: 1500)
        metaTagImage: gatsbyImageData(width: 1200, height: 630)
      }
      author {
        name
        slug
      }
      body {
        body
      }
      category {
        name
        slug
        description
      }
      tags
    }
    related: allContentfulBlogPost(
      filter: { category: { id: { eq: $categoryId } }, slug: { ne: $slug } }
      sort: { fields: publishDate, order: DESC }
      limit: 6
    ) {
      edges {
        node {
          id
          title
          publishDate(formatString: "MMMM Do, YYYY")
          slug
          heroImage {
            gatsbyImageData(width: 400, quality: 70, placeholder: NONE)
          }
          metaDescription
          author {
            name
          }
          category {
            name
          }
        }
      }
    }
    relatedProjects: allContentfulProjects(
      filter: {
        category: {
          elemMatch: { slug: { regex: $testimonialCategorySlugExp } }
        }
      }
      limit: 4
    ) {
      edges {
        node {
          id
          title
          slug
          city
          zipCode
          category {
            slug
          }
          image: thumbnail {
            gatsbyImageData(width: 400, quality: 70, placeholder: NONE)
          }
        }
      }
    }
  }
`
